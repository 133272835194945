:root {
  --text-primary: #47B4AC;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-clearall {
  all:unset;
}

.text-grey {
  color: #727272;
}
a:hover {
  cursor: pointer;
}

button.a {
  color: var(--text-primary);
  text-decoration: underline;
}

/* overrides the side nav menu open and close class from the template so that we dont have to 
edit the body element which the template uses to add remove class to open and close the modal */
.small-saidbar.nav-open {
  left: 0 !important;
  transition: left ease-in-out .5;
}
@media screen and (min-width: 501px) {
  .small-saidbar.nav-closed {
    display: block !important;
  }
}

.ri-close-line {
  display: none;
}

@media screen and (max-width: 1299px) {
  .small-saidbar.nav-closed {
    left: -300px !important;
    transition: left ease-in-out .5;
  }
  .ri-close-line {
    display: block;
  }
}